// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width:667.98px){.af5nyoMy{display:flex;flex-wrap:wrap}}.FCoG7Zud{flex-basis:50%;padding:0 0 40px}@media(min-width:667.98px){.FCoG7Zud{padding:0}}@media(min-width:947.98px){.FCoG7Zud{padding:0 0 40px}}.firZ2Zhm{display:none}@media(min-width:667.98px){.firZ2Zhm{display:block;flex-basis:100%;padding:0}}.Pzag-3Xa{height:auto}@media(min-width:667.98px){.Pzag-3Xa{max-width:544px;width:80%}}.WPBoqdRC{font-size:max(32px,min(4vw,50px));font-weight:400;letter-spacing:-.02em;line-height:1.1;line-height:99.28%;margin:0;padding:0 0 32px;transition:font-size .15s}@media(min-width:667.98px){.WPBoqdRC{max-width:80%;padding:0 0 70px}.YVF1nIAX{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "af5nyoMy",
	"cell": "FCoG7Zud",
	"cellDate": "firZ2Zhm",
	"typo": "Pzag-3Xa",
	"title": "WPBoqdRC",
	"titleDate": "YVF1nIAX"
};
module.exports = ___CSS_LOADER_EXPORT___;
